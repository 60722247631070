import React from 'react'
import { ENGLISH_TITLE } from '../../components/data/English'
import { SEO } from '../../components/data/SEO'
import { CardService } from '../../components/molecules/Card/CardService'
import { LayoutService } from '../../components/organisms/Layout/LayoutService'

const cardList = [
  {
    serviceName: 'M&A・投資',
    imagePathPc: '/service/img/manda/manda1.jpg',
    imagePathSp: '/service/img/manda/manda1sp.jpg',
    title: '相談からクロージングまでトータルサポート',
    content:
      '相談から候補企業の探索・選定、企業価値評価、契約書などの各種資料作成、条件交渉、クロージングまで、経験豊かなコンサルタントがフルサポートいたします。',
    groupList: [
      { text: 'CRGインベストメント株式会社', url: 'https://crg-ivm.co.jp/service/' },
    ],
    columnNumber: 2,
  },
  {
    serviceName: '事業承継',
    imagePathPc: '/service/img/manda/manda2.jpg',
    imagePathSp: '/service/img/manda/manda2sp.jpg',
    title: '後継者不足の課題解決',
    content: '中小企業の事業承継問題に真剣に向き合ってまいります。',
    groupList: [
      { text: 'CRGインベストメント株式会社', url: 'https://crg-ivm.co.jp/service/' },
    ],
    columnNumber: 2,
  },
]

const Page = () => {
  return (
    <LayoutService
      heading="M&A・投資事業"
      headingEnglish={ENGLISH_TITLE.MandA}
      catchCopy="総合人材サービス企業のグループ会社だからこそ可能な新しいM&Aサービスを提供しています。"
    >
      <div className="bg-white pc:px-8">
        <section className="pc:py-12">
          <ul className="grid gap-x-8 gap-y-12 place-items-center pc:place-items-start pc:grid-cols-2">
            {cardList.map((card, i) => (
              <CardService key={i} {...card} />
            ))}
          </ul>
        </section>
      </div>
    </LayoutService>
  )
}

export default Page

/**
 * headタグの内容
 */
/** */
export const Head = () => <SEO title="M&A・投資事業" url="/service/manda" />
